import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useContext } from 'react';
import Modal from 'react-modal';
import { IoClose, IoWarning } from "react-icons/io5";
import { AuthContext } from '../../store/auth-context';


function ModalErrorToken({setModalError, visible}) {

    const authCtx = useContext(AuthContext);

    return(
        <Modal
            isOpen={visible}
            onRequestClose={() => authCtx.logout()}
            className="error-modal"
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                    zIndex: 10,
                },
                content: {
                    zIndex: 11,
                },
            }}
        >
            <div>
                <IoClose
                    className="close-icon"
                    onClick={() => authCtx.logout()}
                />
            </div>
            <div className="error-modal-title">
                ATTENZIONE <IoWarning className="text-icon-right" />
            </div>
            <div>Token scaduto, rieseguire il login</div>
        </Modal>
    );
}

export default ModalErrorToken;