import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useEffect, useContext, useState } from 'react';
import { PortaleContext } from '../../store/portale-context';
import { AuthContext } from '../../store/auth-context';
import ModalLoading from '../modals/ModalLoading';
import ModalError from '../modals/ModalError';
import { useNavigate } from "react-router-dom";

function VerificaToken() {

    const portaleCtx = useContext(PortaleContext);
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });

    useEffect(() => {
        checkValidityToken();
    }, []);

    const navigate = useNavigate();

    const checkValidityToken = () => {
        setIsLoading(true);
        var data = {
            'op': 'checkValidityToken',
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setIsLoading(false)
                    if (responseJson.status === false) {
                        navigate('/login', { replace: true });
                        return;
                    }
                    authCtx.setImpostazioniCtx(responseJson.impostazioni);
                    authCtx.login();
                    
                    return;
                })
                .catch(error => {
                    setIsLoading(false);
                    console.log(error);
                    navigate('/login', { replace: true });
                })
        );
    };

    return (
        <>
            <ModalLoading visible={isLoading} color="#063970" />
            <ModalError setModalError={setModalError} modalState={modalError}/>
        </>
    );
}

export default VerificaToken;