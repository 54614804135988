import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoClose, IoTrashBinSharp } from "react-icons/io5";
import { PortaleContext } from '../../store/portale-context';
import { AuthContext } from '../../store/auth-context';
import ModalLoading from './ModalLoading';
import ModalError from './ModalError';

function ModalEliminaTipoRichiesta({chiudiModalEliminaTipoRichiesta, visible, aggiornaDatiPortale}) {

    const portaleCtx = useContext(PortaleContext);
    const authCtx = useContext(AuthContext);
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalLoading, setModalLoading] = useState(false);

    const eliminaTipoRichiesta = () => {
        setModalLoading(true);

        var data = {
            'op': 'eliminaTipoRichiesta',
            'Codice': portaleCtx.tipoRichiestaCorrente.Codice,
            'tipo': portaleCtx.activeTab
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        setModalError({flgModal: true, titolo: 'ATTENZIONE', testo: responseJson.errore})
                        return;
                    }

                    aggiornaDatiPortale(responseJson);
                    chiudiModalEliminaTipoRichiesta();

                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nell'eliminazione del tipo richiesta"})
                })
        );
    }

    return(
            <Modal
                isOpen={visible}
                onRequestClose={chiudiModalEliminaTipoRichiesta}
                className="info-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >                
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={chiudiModalEliminaTipoRichiesta}
                    />
                </div>

                <h3 className="info-modal-title" style={{marginTop: '0px'}}> 
                    Elimina tipoRichiesta
                </h3>

                <div className='contact-form'>

                    {
                        portaleCtx.tipoRichiestaCorrente != null ?
                        <label className="input-filtro-cliente-articoli form-input" style={{display: 'flex', justifyContent: 'center'}}>
                            <div>Eliminare definitivamente il tipo richiesta <b>{portaleCtx.tipoRichiestaCorrente.Descrizione}</b>?</div>
                        </label>
                        : <></>
                    }
                    

                    <div className="buttons" style={{width: '90%'}}>
                        <button
                            className="button"
                            onClick={chiudiModalEliminaTipoRichiesta}>
                            Annulla <IoArrowUndoSharp className="text-icon-right" />
                        </button>
                        <button
                            className="button red"
                            onClick={() => {
                                eliminaTipoRichiesta();
                            }}
                        >
                            Elimina <IoTrashBinSharp className="text-icon-right" />
                        </button>
                    </div>
                </div>

                <ModalError setModalError={setModalError} modalState={modalError}/>
                <ModalLoading visible={modalLoading} color="#063970" />

            </Modal>
    );
}

export default ModalEliminaTipoRichiesta;