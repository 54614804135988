import '../../Portale.css'; // Importa il file CSS per lo stile

import React, {useContext} from 'react';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoCheckmarkCircle, IoClose } from "react-icons/io5";
import { PortaleContext } from '../../store/portale-context';

function ModalVistato({setModalVistato, visible, impostaVistato}) {

    const portaleCtx = useContext(PortaleContext);

    return(
            <Modal
                isOpen={visible}
                onRequestClose={() => {setModalVistato(false)}}
                className="info-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >                
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={() => {setModalVistato(false)}}
                    />
                </div>

                <h3 className="info-modal-title" style={{marginTop: '0px'}}>Impostare il messaggio come chiuso?</h3>
                
                <div className='contact-form'>

                    <div className="buttons" style={{width: '90%'}}>
                        <button
                            className="button red"
                            onClick={() => {setModalVistato(false)}}>
                            No <IoArrowUndoSharp className="text-icon-right" />
                        </button>
                        <button
                            className="button green"
                            onClick={() => {impostaVistato()}}
                        >
                            Si <IoCheckmarkCircle className="text-icon-right" />
                        </button>
                    </div>
                </div>
            </Modal>
    );
}

export default ModalVistato;