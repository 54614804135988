import '../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import ModalLoading from './modals/ModalLoading';
import { ColumnFilter } from './ColumnFilter';
import FilterSection from './FilterSection';
import { PortaleContext } from '../store/portale-context';
import { AuthContext } from '../store/auth-context';
import useViewportHeight from './utils/useViewportHeight';

// modal
import ModalError from './modals/ModalError';
import ModalErrorToken from './modals/ModalErrorToken';
import ModalImpostazioni from './modals/ModalImpostazioni';
import ModalLogout from './modals/ModalLogout';
import ModalGestioneTipiRichiesta from './modals/ModalGestioneTipiRichiesta';
import ModalNuovoTask from './modals/ModalNuovoTask';
import ModalChat from './modals/ModalChat';
import ModalVistato from './modals/ModalVistato';

// tabelle
import NonVistatiTable from './tables/NonVistatiTable';
import VistatiTable from './tables/VistatiTable';

import { IoAddCircleSharp, IoCaretDown, IoCaretUp, IoCloudUpload, IoSettingsSharp, IoSyncCircle, IoSyncCircleOutline } from 'react-icons/io5';

function MainPage() {

    const authCtx = useContext(AuthContext);
    const portaleCtx = useContext(PortaleContext);
    const vh = useViewportHeight();

    const [flgVistati, setFlgVistati] = useState(false);
    const [contatoreRichieste, setContatoreRichieste] = useState([0, 0, 0]);

    const [modalLoading, setModalLoading] = useState(false);
    const [modalErrorToken, setModalErrorToken] = useState(false);
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalImpostazioni, setModalImpostazioni] = useState(false);
    const [modalLogout, setModalLogout] = useState(false);
    const [modalTipiRichiesta, setModalTipiRichiesta] = useState(false);
    const [modalNuovoTask, setModalNuovoTask] = useState(false);
    const [modalChat, setModalChat] = useState(false);
    const [modalVistato, setModalVistato] = useState(false);

    const [dataDal, setDataDal] = useState('');
    const [dataAl, setDataAl] = useState('');

    const [collapsed, setCollapsed] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    //funzioni grafiche
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        // Clean up: rimuovi l'event listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    const getCurrentDate = () => {
        const date = new Date(); // creates a new date object representing the current date and time

        let day = date.getDate(); // get the day as a number (1-31)
        let month = date.getMonth() + 1; // get the month as a number (0-11) so add 1 to make it (1-12)
        let year = date.getFullYear(); // get the year as four digits (yyyy)

        // Ensure day and month are always two digits by adding leading zeros if needed
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${year}-${month}-${day}`; // returns the date in DD-mm-yyyy format
    }

    function getDefaultFromDate() {
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today.toISOString().split('T')[0];
    };

    //effect operativi
    useLayoutEffect(() => {
        setDataAl(getCurrentDate());
        setDataDal(getDefaultFromDate());
    }, []);

    useEffect(() => {
        getTask();
    }, [portaleCtx.activeTab]);

    useEffect(() => {
        getTask();
    }, [modalChat]);

    const aggiornaDatiPortale = (dati) => {
        setContatoreRichieste(dati.conteggi);
        portaleCtx.setVistatiCtx(dati.vistati);
        portaleCtx.setNonVistatiCtx(dati.nonVistati);

        portaleCtx.setTipiRichiestaCtx(dati.tipiRichiesta);
        portaleCtx.setUtentiCtx(dati.utenti);
        portaleCtx.setClientiCtx(dati.clienti);
    }

    //caricamento dati necessari
    const getTask = () => {

        setModalLoading(true);
        var data = {
            'op': 'getTask',
            'tipo': portaleCtx.activeTab,
            'dataDal': dataDal == '' ? getDefaultFromDate() : dataDal,
            'dataAl': dataAl == '' ? getCurrentDate() : dataAl,
        };
        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura degli ordini' });
                        }
                        return;
                    }
                    aggiornaDatiPortale(responseJson);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura degli ordini' })
                })
        );
    };

    const columnsVistati = [
        portaleCtx.activeTab === 1 || portaleCtx.activeTab === 3 ? { Header: 'Mittente', accessor: 'NomeMittente', Filter: ColumnFilter } : null,
        portaleCtx.activeTab === 2 || portaleCtx.activeTab === 3 ? { Header: 'Destinatario', accessor: 'NomeDestinatario', Filter: ColumnFilter } : null,
        { Header: 'Cliente\\Fornitore', accessor: 'RagSocCliente', Filter: ColumnFilter },
        { Header: 'Tipo richiesta', accessor: 'DesTipoRichiesta', Filter: ColumnFilter },
        { Header: 'Commessa', accessor: 'Commessa', Filter: ColumnFilter },
        { Header: 'Data apertura', accessor: 'DataApertura', Filter: ColumnFilter },
        { Header: 'Ora apertura', accessor: 'OraApertura', Filter: ColumnFilter },
        { Header: 'Scadenza', accessor: 'DataScadenza', Filter: ColumnFilter },
        { Header: 'Messaggio Iniziale', accessor: 'MessaggioIniziale', Filter: ColumnFilter },
    ].filter(column => column !== null);

    const columnsNonVistati = [
        portaleCtx.activeTab === 1 || portaleCtx.activeTab === 3 ? { Header: 'Mittente', accessor: 'NomeMittente', Filter: ColumnFilter } : null,
        portaleCtx.activeTab === 2 || portaleCtx.activeTab === 3 ? { Header: 'Destinatario', accessor: 'NomeDestinatario', Filter: ColumnFilter } : null,
        { Header: 'Cliente\\Fornitore', accessor: 'RagSocCliente', Filter: ColumnFilter },
        { Header: 'Tipo richiesta', accessor: 'DesTipoRichiesta', Filter: ColumnFilter },
        { Header: 'Commessa', accessor: 'Commessa', Filter: ColumnFilter },
        { Header: 'Data apertura', accessor: 'DataApertura', Filter: ColumnFilter },
        { Header: 'Ora apertura', accessor: 'OraApertura', Filter: ColumnFilter },
        { Header: 'Scadenza', accessor: 'DataScadenza', Filter: ColumnFilter },
        { Header: 'Messaggio Iniziale', accessor: 'MessaggioIniziale', Filter: ColumnFilter },
    ].filter(column => column !== null);

    const chiudiModalImpostazioni = () => {
        setModalImpostazioni(false);
    }

    const apriModalLogout = () => {
        setModalImpostazioni(false);
        setModalLogout(true);
    }

    const chiudiModalLogout = (logout) => {
        setModalLogout(false);
        if (logout) authCtx.logout();
        else setModalImpostazioni(true);
    }

    const apriModalTipiRichiesta = () => {
        setModalImpostazioni(false);
        setModalTipiRichiesta(true);
    }

    const chiudiModalTipiRichiesta = () => {
        setModalTipiRichiesta(false);
        setModalImpostazioni(true);
    }

    const apriModalVistato = (riga) => {
        portaleCtx.setIdTaskCorrenteCtx(riga.Id);
        setModalVistato(true);
    }

    const salvaNuovoTask  = async (dati) => {

        setModalNuovoTask(false);
        setModalLoading(true);

        let commessa = '';
        if (dati.commessa.value != undefined) commessa = dati.commessa.value;
        let dataScadenza = '';
        if (dati.dataScadenza != undefined) dataScadenza = dati.dataScadenza;

        let nomeFile = '';
        if (dati.allegato != null) {
            nomeFile = await handleUploadFiles(dati.allegato);
        }

        var data = {
            'op': 'salvaNuovoTask',
            'destinatario': dati.destinatario.value,
            'cliente': dati.cliente.value,
            'commessa': commessa,
            'tipoRichiesta': dati.tipoRichiesta.value,
            'dataScadenza': dataScadenza,
            'messaggio': dati.messaggio,
            'tipo': portaleCtx.activeTab,
            'dataDal': dataDal == '' ? getDefaultFromDate() : dataDal,
            'dataAl': dataAl == '' ? getCurrentDate() : dataAl,
            'nomeFile': nomeFile,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' });
                        }
                        return;
                    }
                    aggiornaDatiPortale(responseJson);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' })
                })
        );
    };

    const impostaVistato = () => {

        setModalVistato(false);
        setModalLoading(true);
        var data = {
            'op': 'impostaVistato',
            'idTask': portaleCtx.idTaskCorrente,
            'tipo': portaleCtx.activeTab
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nell'aggiornamento dello stato" });
                        }
                        return;
                    }
                    aggiornaDatiPortale(responseJson);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nell'aggiornamento dello stato" })
                })
        );
    };

    const getDatiChat = (riga) => {

        portaleCtx.setIdTaskCorrenteCtx(riga.Id);

        setModalLoading(true);
        var data = {
            'op': 'getDatiChat',
            'idTask': riga.Id
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dei messaggi' });
                        }
                        return;
                    }
                    portaleCtx.setMessaggiCtx(responseJson.messaggi);
                    portaleCtx.setTestataCtx(responseJson.testata);
                    setModalChat(true);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dai messaggi' })
                })
        );
    }

    const inviaMessaggio = async (msg, files) => {

        msg = msg.replace(/\r\n|\r|\n/g, '\r\n');

        setModalLoading(true);

        let nomeFile = '';
        if (files != null) {
            nomeFile = await handleUploadFiles(files);
        }

        var data = {
            'op': 'inviaMessaggio',
            'messaggio': msg,
            'idTask': portaleCtx.idTaskCorrente,
            'tipo': portaleCtx.activeTab,
            'nomeFile': nomeFile,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dei messaggi' });
                        }
                        return;
                    }
                    portaleCtx.setMessaggiCtx(responseJson.messaggi);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' })
                })
        );
    };

    const handleUploadFiles = async (files) => {
        const formData = new FormData();
        formData.append('files[]', files[0]);

        try {
            const response = await fetch(authCtx.uploadUrl, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel caricamento allegato' })
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='portale-container' style={{ height: `${vh}px` }}>
            {
                false ?
                    <FilterSection onFilterChange={getTask} />
                    :
                    <></>
            }

            <div className="dropdown-menu">
                <div className="dropdown-tabs">
                    <div className='tab'>
                        <div>
                            {portaleCtx.activeTab === 1 ? 'LE MIE ATTIVITÀ ' : portaleCtx.activeTab === 2 ? 'ATTIVITÀ RICHIESTE ' : 'TUTTE '}
                            <span className='bullet'>{contatoreRichieste[portaleCtx.activeTab - 1]}</span>
                        </div>
                    </div>
                    <div className="menu-button" onClick={toggleCollapse}>
                        Menu {collapsed ? <IoCaretUp className="text-icon-right" /> : <IoCaretDown className="text-icon-right" />}
                    </div>
                </div>
                {collapsed && (
                    <>
                        <div className="dropdown-overlay" onClick={toggleCollapse}>
                            <div className="dropdown-content">
                                <div className={`dropdown-tab ${portaleCtx.activeTab === 1 ? 'active' : ''}`} style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '20px', marginRight: '20px' }} onClick={() => { portaleCtx.setActiveTabCtx(1); toggleCollapse(); }}>
                                    <div>LE MIE ATTIVITÀ <span className={`${portaleCtx.activeTab === 1 ? 'dropdown-bullet active' : 'dropdown-bullet'}`}>{contatoreRichieste[0]}</span></div>
                                </div>
                                <div className={`dropdown-tab ${portaleCtx.activeTab === 2 ? 'active' : ''}`} style={{ marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }} onClick={() => { portaleCtx.setActiveTabCtx(2); toggleCollapse(); }}>
                                    <div>ATTIVITÀ RICHIESTE <span className={`${portaleCtx.activeTab === 2 ? 'dropdown-bullet active' : 'dropdown-bullet'}`}>{contatoreRichieste[1]}</span></div>
                                </div>
                                <div className={`dropdown-tab ${portaleCtx.activeTab === 3 ? 'active' : ''}`} style={{ marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }} onClick={() => { portaleCtx.setActiveTabCtx(3); toggleCollapse(); }}>
                                    <div>TUTTE <span className={`${portaleCtx.activeTab === 3 ? 'dropdown-bullet active' : 'dropdown-bullet'}`}>{contatoreRichieste[2]}</span></div>
                                </div>

                                <div className="dropdown-icons-container">
                                    <div className="dropdown-icon" onClick={() => { setModalNuovoTask(true); toggleCollapse(); }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><IoAddCircleSharp style={{ height: '50px', width: '50px', color: '#1c8d42' }} /></div>
                                    </div>
                                    <div className="dropdown-icon" onClick={() => { setModalImpostazioni(true); toggleCollapse(); }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><IoSettingsSharp style={{ height: '50px', width: '50px', color: '#de7504' }} /></div>
                                    </div>
                                    <div className="dropdown-icon"
                                        onClick={() => { window.open('https://portale-web-messaggistica.csd-hosting.it/server/cronjob/day.php', '_blank', 'noopener,noreferrer') }} >
                                        <div style={{ display: 'flex', alignItems: 'center' }}><IoCloudUpload style={{ height: '50px', width: '50px', color: '#c79f10' }} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="tabs">
                <div className={`tab ${portaleCtx.activeTab === 1 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(1)}>
                    <div>LE MIE ATTIVITÀ <span className={`${portaleCtx.activeTab === 1 ? 'bullet active' : 'bullet'}`}>{contatoreRichieste[0]}</span></div>
                </div>
                <div className={`tab ${portaleCtx.activeTab === 2 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(2)} style={{ marginLeft: '30px', marginRight: '30px' }}>
                    <div>ATTIVITÀ RICHIESTE <span className={`${portaleCtx.activeTab === 2 ? 'bullet active' : 'bullet'}`}>{contatoreRichieste[1]}</span></div>
                </div>
                <div className={`tab ${portaleCtx.activeTab === 3 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(3)}>
                    <div>TUTTE <span className={`${portaleCtx.activeTab === 3 ? 'bullet active' : 'bullet'}`}>{contatoreRichieste[2]}</span></div>
                </div>
                <IoAddCircleSharp className={`tab tab-small green`} style={{ marginLeft: '20px' }} onClick={() => setModalNuovoTask(true)} />
                <IoSettingsSharp className={`tab tab-small orange`} style={{ marginLeft: '10px' }} onClick={() => setModalImpostazioni(true)} />
                <IoCloudUpload className={`tab tab-small yellow`} style={{ marginLeft: '10px' }}
                    onClick={() => { window.open('https://portale-web-messaggistica.csd-hosting.it/server/cronjob/day.php', '_blank', 'noopener,noreferrer') }} />
            </div>

            <div className="sub-tabs"
                style={windowWidth > 1000 ? { display: 'flex', flexDirection: 'row', padding: '0px', alignItems: 'center' }
                    : { display: 'flex', flexDirection: 'column', padding: '0px', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', marginBottom: '12px' }}>
                    <div className="nav-item" style={!flgVistati ? { border: '2px solid #c00a0a' } : { border: '2px solid transparent' }} onClick={() => setFlgVistati(false)}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#c00a0a' }}>
                            Aperti
                            <span className={`${!flgVistati ? 'bullet active' : 'bullet'} sub-bullet`} style={{ backgroundColor: '#c00a0a' }}>
                                {portaleCtx.nonVistati.length}
                            </span>
                        </div>
                    </div>
                    <div className="nav-item" style={flgVistati ? { border: '2px solid #0c8133' } : { border: '2px solid transparent' }} onClick={() => setFlgVistati(true)}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#0c8133' }}>
                            Chiusi
                            <span className={`${flgVistati ? 'bullet active' : 'bullet'} sub-bullet`} style={{ backgroundColor: '#0c8133' }}>
                                {portaleCtx.vistati.length}
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '12px', marginLeft: '15px' }}>
                    <div className='filtro-date-container'>
                        Dal
                        <input
                            className='filtro-date'
                            type="date"
                            placeholder=""
                            value={dataDal}
                            onChange={(e) => setDataDal(e.target.value)}
                        />
                    </div>
                    <div className='filtro-date-container'>
                        Al
                        <input
                            className='filtro-date'
                            type="date"
                            placeholder=""
                            value={dataAl}
                            onChange={(e) => setDataAl(e.target.value)}
                        />
                    </div>
                    <div>
                        <IoSyncCircle className={`tab tab-small blue`} onClick={getTask} />
                    </div>
                </div>
            </div>

            {
                !flgVistati ?
                    <NonVistatiTable columns={columnsNonVistati} data={portaleCtx.nonVistati} getDatiChat={getDatiChat} apriModalVistato={apriModalVistato} />
                    :
                    <VistatiTable columns={columnsVistati} data={portaleCtx.vistati} getDatiChat={getDatiChat} />
            }

            <ModalError setModalError={setModalError} modalState={modalError} />
            <ModalErrorToken visible={modalErrorToken} />
            <ModalLoading visible={modalLoading} color="#063970" />
            <ModalLogout visible={modalLogout} chiudiModalLogout={chiudiModalLogout} />
            <ModalImpostazioni chiudiModalImpostazioni={chiudiModalImpostazioni} visible={modalImpostazioni} apriModalLogout={apriModalLogout} apriModalTipiRichiesta={apriModalTipiRichiesta} />
            <ModalNuovoTask setModalNuovoTask={setModalNuovoTask} visible={modalNuovoTask} salvaNuovoTask={salvaNuovoTask} />
            <ModalChat visible={modalChat} setModalChat={setModalChat} messaggi={portaleCtx.messaggi} inviaMessaggio={inviaMessaggio} setModalError={setModalError}/>
            <ModalVistato setModalVistato={setModalVistato} visible={modalVistato} impostaVistato={impostaVistato} />
            <ModalGestioneTipiRichiesta chiudiModalTipiRichiesta={chiudiModalTipiRichiesta} visible={modalTipiRichiesta} aggiornaDatiPortale={aggiornaDatiPortale} />

        </div >
    );
}

export default MainPage;
