import '../../Portale.css'; // Importa il file CSS per lo stile

import React from 'react';
import Modal from 'react-modal';
import { IoCheckmarkCircle, IoCloseCircleSharp } from "react-icons/io5";

function ModalLogout({chiudiModalLogout, visible}) {

    return(
            <Modal
                isOpen={visible}
                onRequestClose={() => chiudiModalLogout(false)}
                className="info-modal modal-logout border-large"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >

                <div className='contact-form'>
                    <label className="input-filtro-cliente-articoli form-input">
                        <b style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>Effettuare il logout?</b>
                    </label>

                    <div className="buttons" style={{width: '90%'}}>
                        <button
                            className="button green"
                            onClick={() => {chiudiModalLogout(true)}}>
                            Si <IoCheckmarkCircle className="text-icon-right" />
                        </button>
                        <button
                            className="button red"
                            onClick={() => {chiudiModalLogout(false)}}>
                            No <IoCloseCircleSharp className="text-icon-right" />
                        </button>
                    </div>
                </div>
            </Modal>
    );
}

export default ModalLogout;