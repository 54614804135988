import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoAttach, IoCheckmarkCircle, IoClose, IoWarning } from "react-icons/io5";
import { AuthContext } from '../../store/auth-context';
import { PortaleContext } from '../../store/portale-context';

function ModalNuovoTask({ setModalNuovoTask, visible, salvaNuovoTask }) {

    const authCtx = useContext(AuthContext);
    const portaleCtx = useContext(PortaleContext);
    const [commesse, setCommesse] = useState([]);
    const [dati, setDati] = useState({
        destinatario: null,
        cliente: null,
        commessa: null,
        tipoRichiesta: null,
        dataScadenza: null,
        messaggio: '',
        allegato: null
    });
    const [msgErroreVisibile, setMsgErroreVisibile] = useState(false);

    useEffect(() => {
        setDati({
            destinatario: null,
            cliente: null,
            commessa: null,
            tipoRichiesta: null,
            dataiScadenza: null,
            messaggio: '',
            allegato: null
        });
        setCommesse([]);
        setMsgErroreVisibile(false);
    }, [visible]);

    const checkDatiInseriti = () => {
        if (dati.destinatario != null) {
            if (dati.destinatario != null && dati.cliente != null && dati.tipoRichiesta != null && dati.messaggio != '') {
                salvaNuovoTask(dati);
            } else setMsgErroreVisibile(true);
        } else setMsgErroreVisibile(true);
    }

    const impostaCliente = (e) => {
        let codCliente = e.target.value;
        setDati({ ...dati, cliente: codCliente, commessa: 'empty' });

        for (let i = 0; i < portaleCtx.clienti.length; i++) {
            if (portaleCtx.clienti[i].CodCliFor == codCliente) {
                setCommesse(portaleCtx.clienti[i].Commesse);
            }
        }
    }

    const handleDestinatarioChange = (selectedOption) => {
        setDati({ ...dati, destinatario: selectedOption });
    };

    const handleClienteChange = (selectedOption) => {
        setDati({ ...dati, cliente: selectedOption, commessa: '' });
        setCommesse(selectedOption.Commesse);
    };

    const handleCommessaChange = (selectedOption) => {
        setDati({ ...dati, commessa: selectedOption });
    };

    const handleTipoRichiestaChange = (selectedOption) => {
        setDati({ ...dati, tipoRichiesta: selectedOption });
    };

    //allegati
    const handleScegliAllegato = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (e) => {
        setDati({ ...dati, allegato: e.target.files });
    };
    //render allegati
    const isImage = (fileName) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
    };

    const getFileNameFromUrl = (url) => {
        // Split the URL by slashes and get the last part
        if (url == null) return "";
        return url.split('/').pop();
    };

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => setModalNuovoTask(false)}
            className="info-modal border-large"
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                    zIndex: 10,
                },
                content: {
                    zIndex: 11,
                },
            }}
        >
            <div>
                <IoClose
                    className="close-icon"
                    onClick={() => setModalNuovoTask(false)}
                />
            </div>

            <h3 className="info-modal-title" style={{ marginTop: '0px' }}>Nuova attività</h3>

            <div className='contact-form'>
                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Destinatario*</div>
                    <Select
                        className='select-item'
                        options={portaleCtx.utenti}
                        value={dati.utenti}
                        onChange={handleDestinatarioChange}
                        isSearchable
                        placeholder="Seleziona il destinatario"
                    //isMulti={true}
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Cliente\Fornitore*</div>
                    <Select
                        className='select-item'
                        options={portaleCtx.clienti}
                        value={dati.cliente}
                        onChange={handleClienteChange}
                        isSearchable
                        placeholder="Seleziona il cliente o fornitore"
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Commessa</div>
                    <Select
                        className='select-item'
                        options={commesse}
                        value={dati.commessa}
                        onChange={handleCommessaChange}
                        isSearchable
                        placeholder="Seleziona la commessa"
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Tipo richiesta*</div>
                    <Select
                        className='select-item'
                        options={portaleCtx.tipiRichiesta}
                        value={dati.tipoRichiesta}
                        onChange={handleTipoRichiestaChange}
                        isSearchable
                        placeholder="Seleziona il tipo richiesta"
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Data scadenza</div>
                    <input
                        className='valori'
                        type="date"
                        placeholder=""
                        value={dati.dataInizio}
                        style={{ width: '96%', padding: '4px 10px' }}
                        onChange={(e) => setDati({ ...dati, dataScadenza: e.target.value })}
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Messaggio*</div>
                    <textarea
                        rows={10}
                        className='valori'
                        type="text"
                        placeholder=""
                        value={dati.messaggio}
                        style={{ height: 'auto', fontFamily: 'inherit', fontSize: '15px', width: '100%' }}
                        maxLength={500}
                        onChange={(e) => setDati({ ...dati, messaggio: e.target.value })}
                    />
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Allegato</div>
                    <button onClick={handleScegliAllegato} className={'icon-button'}
                        style={{ marginTop: '10px', marginBottom: '10px', height: '40px', width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <IoAttach className="icon" />
                        {dati.allegato != null ? dati.allegato[0].name : ""}
                    </button>
                </label>
                <input
                    type="file"
                    id="fileInput"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                {
                    msgErroreVisibile ?
                        <div key="error_message" style={{ margin: '10px', border: '1px solid #f05a5a', borderRadius: '5px', padding: '10px', backgroundColor: '#fff', width: '90%' }}>
                            <div className="error-modal-title">
                                ATTENZIONE <IoWarning className="text-icon-right" />
                            </div>
                            <div className='error-modal-text'>Compilare tutti i campi per proseguire</div>
                        </div>
                        :
                        null
                }

                <div className="buttons" style={{ width: '90%' }}>
                    <button
                        className="button red"
                        onClick={() => { setModalNuovoTask(false) }}>
                        Annulla <IoArrowUndoSharp className="text-icon-right" />
                    </button>
                    <button
                        className="button green"
                        onClick={() => {
                            checkDatiInseriti();
                        }}
                    >
                        Salva <IoCheckmarkCircle className="text-icon-right" />
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalNuovoTask;