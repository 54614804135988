import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useContext } from 'react';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoClose, IoCloseCircleSharp, IoPencilSharp } from "react-icons/io5";
import { AuthContext } from '../../store/auth-context';

function ModalImpostazioni({chiudiModalImpostazioni, visible, apriModalLogout, apriModalTipiRichiesta}) {
    const authCtx = useContext(AuthContext);

    return(
            <Modal
                isOpen={visible}
                onRequestClose={chiudiModalImpostazioni}
                className="info-modal border-large"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >                
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={chiudiModalImpostazioni}
                    />
                </div>

                <h3 className="info-modal-title" style={{marginTop: '0px'}}> 
                    Impostazioni 
                </h3>
                <h3 className="info-modal-title" style={{marginTop: '10px'}}> 
                    Utente: {authCtx.impostazioni.username}
                </h3>

                <div className='contact-form'>

                    <div className="buttons" style={{width: '90%', justifyContent: 'center', marginTop: '0px'}}>
                        <button
                            className="button"
                            style={{minWidth: '150px'}}
                            onClick={apriModalLogout}>
                            Logout <IoArrowUndoSharp className="text-icon-right" />
                        </button>
                    </div>

                    <div className="buttons" style={{width: '90%', justifyContent: 'center'}}>
                        <button
                            className="button"
                            style={{minWidth: '150px'}}
                            onClick={apriModalTipiRichiesta}>
                            Tipi richiesta <IoPencilSharp className="text-icon-right" />
                        </button>
                    </div>

                    <div className="buttons" style={{width: '90%', justifyContent: 'center'}}>
                        <button
                            className="button red"
                            style={{minWidth: '150px'}}
                            onClick={chiudiModalImpostazioni}>
                            Chiudi <IoCloseCircleSharp className="text-icon-right" />
                        </button>
                    </div>
                </div>
            </Modal>
    );
}

export default ModalImpostazioni;