import { useEffect } from "react";
import AuthContextProvider from "./store/auth-context";
import PortaleContextProvider from "./store/portale-context";
import PortaleMessaggistica from './PortaleMessaggistica';

const App = () => {

  useEffect(() => {
    // Rimuovi la cronologia della sessione
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
  }, []);

  return (
    <div className='app' id="app">
      <AuthContextProvider>
        <PortaleContextProvider>
          <PortaleMessaggistica />
        </PortaleContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;