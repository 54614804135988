import '../../Portale.css'; // Importa il file CSS per lo stile

import React from 'react';
import Modal from 'react-modal';
import MyLoader from '../MyLoader';

function ModalLoading({visible}) {

    return(
            <Modal
                isOpen={visible}
                className="info-modal-small"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 20,
                    },
                    content: {
                        zIndex: 21,
                    },
                }}
            >
                <div className='contact-form'>

                    <div className="buttons" style={{width: '90%', justifyContent: 'space-around', marginTop: '0px'}}>
                        <MyLoader active={true} color="#063970" modal={true} />
                    </div>
                </div>
                
            </Modal>
    );
}

export default ModalLoading;