import React, { useLayoutEffect, useState, useContext } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { Button } from 'react-bootstrap';
import { IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { PortaleContext } from '../../store/portale-context';

const TipiRichiestaTable = ({ columns, data, apriModificaTipoRichiesta, apriEliminaTipoRichiesta }) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 }, // Imposta il numero di righe per pagina iniziale
        },
        useFilters,
        useSortBy
    );

    useLayoutEffect(() => {

    }, [data]);

    return (
        <div className='custom-table-container' style={{maxHeight: 'calc(100vh - 250px)'}}>
            <table {...getTableProps()} className='custom-table'>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key={'rowHead'} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, idxColHead) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Aggiungi l'icona dell'ordinamento */}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                                    </span>
                                    {/* Aggiungi il filtro */}
                                    <div key={'colHead_'+idxColHead}>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                            <th></th>
                            <th></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIdx) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, cellIdx) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            <b>{cell.render('Cell')}</b>
                                        </td>
                                    );
                                })}
                                <td>
                                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Button
                                            className="button-small"
                                            style={{flex: '1'}}
                                            onClick={() => { apriModificaTipoRichiesta(row.original) }}
                                        >
                                            Modifica <IoPencilSharp className="text-icon-right" />
                                        </Button>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Button
                                            className="button-small red"
                                            style={{flex: '1'}}
                                            onClick={() => { apriEliminaTipoRichiesta(row.original) }}
                                        >
                                            Elimina <IoTrashBinSharp className="text-icon-right" />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TipiRichiestaTable;