import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
  
  import React, { useContext, useEffect } from 'react';
  import { AuthContext } from './store/auth-context';
  import MainPage from "./components/MainPage";
  import VerificaToken from "./components/login/VerificaToken";
  import LoginPage from './components/login/LoginPage';
  
  export default function Portale() {
  
    const authCtx = useContext(AuthContext);
    const isAuthenticated = () => {
      return authCtx.isAuthenticated;
    };
  
    useEffect(() => {
    }, []);
      
    return (
        <Router>
            <Routes>
                <Route path="/login" element={isAuthenticated() ? <Navigate to="/" /> : <LoginPage />} />
                <Route path="/verifica-token" element={isAuthenticated() ? <Navigate to="/" /> : <VerificaToken />} />
                <Route path="/" element={isAuthenticated() ? <MainPage /> : <Navigate to="/verifica-token" />} />
                <Route path="/*" element={isAuthenticated() ? <Navigate to="/" /> : <Navigate to="/verifica-token" />} />
            </Routes>
        </Router>
    );
  }