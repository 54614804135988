import React, { useLayoutEffect, useState, useContext } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { PortaleContext } from '../../store/portale-context';
import { Button } from 'react-bootstrap';
import { IoChatbubblesSharp } from 'react-icons/io5';

const VistatiTable = ({ columns, data, getDatiChat }) => {

    const [expandedRows, setExpandedRows] = useState([]);
    const portaleCtx = useContext(PortaleContext);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 }, // Imposta il numero di righe per pagina iniziale
            autoResetFilters: false
        },
        useFilters,
        useSortBy,
    );

    useLayoutEffect(() => {

    }, [data]);

    return (
        <div className='custom-table-container'>
            <table {...getTableProps()} className='custom-table'>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key={'rowHead'} {...headerGroup.getHeaderGroupProps()}>
                            <th key={'th_first'}></th>
                            {headerGroup.headers.map((column, idxColHead) => (
                                <th key={'th_' + idxColHead}>
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Aggiungi l'icona dell'ordinamento */}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                                        </span>
                                    </div>
                                    {/* Aggiungi il filtro */}
                                    <div key={'colHead_' + idxColHead}>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIdx) => {
                        prepareRow(row);
                        return (
                            <tr key={'row_' + rowIdx} {...row.getRowProps()}>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            className="button-small"
                                            onClick={() => { getDatiChat(row.original) }}
                                        >
                                            <IoChatbubblesSharp className="icon" />
                                        </Button>
                                    </div>
                                </td>
                                {row.cells.map((cell, cellIdx) => {
                                    return (
                                        <td style={cellIdx <= 3 ? { fontWeight: 'bold' } : null} {...cell.getCellProps()}>
                                            {
                                                cellIdx == 0 ?
                                                    //badge messaggi non letti
                                                    <>
                                                        {cell.render('Cell')}
                                                        {row.original.NumNonLetti > 0 && (
                                                            <>
                                                                <br />
                                                                <span className="badge-non-letti">
                                                                    {row.original.NumNonLetti} da leggere
                                                                </span>
                                                            </>
                                                        )}
                                                    </>
                                                    :
                                                    //data scadenza
                                                    cellIdx == 6 && row.original.DataScadenza == '01/01/1900' ?
                                                        "-"
                                                        : cell.render('Cell')
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default VistatiTable;