import '../Portale.css'; // Importa il file CSS per lo stile

import React from 'react';

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div className='column-filter-container'>
      <input
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder={`Filtro ${column.Header}`}
        className='column-filter-item'
      />
    </div>
  );
};