import '../../Portale.css'; // Importa il file CSS per lo stile

import React from 'react';
import Modal from 'react-modal';
import { IoClose, IoWarning } from "react-icons/io5";


function ModalError({setModalError, modalState}) {

    return(
        <Modal
            isOpen={modalState.flgModal}
            onRequestClose={() => setModalError({ flgModal: false, titolo: "", testo: "" })}
            className="error-modal"
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                    zIndex: 10,
                },
                content: {
                    zIndex: 11,
                },
            }}
        >
            <div>
                <IoClose
                    className="close-icon"
                    onClick={() => setModalError({ flgModal: false, titolo: "", testo: "" })}
                />
            </div>
            <div className="error-modal-title">
                {modalState.titolo} <IoWarning className="text-icon-right" />
            </div>
            <div>{modalState.testo}</div>
        </Modal>
    );
}

export default ModalError;