import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useContext } from 'react';
import ModalLoading from '../modals/ModalLoading';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../store/auth-context';
import ModalError from '../modals/ModalError';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";
import { IoEyeSharp } from 'react-icons/io5';

function LoginPage() {

    const authCtx = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [pwdVisibile, setPwdVisibile] = useState(false);

    const navigate = useNavigate();

    const checkLogin = () => {
        return username !== '' && password !== '';
    }

    const handleCaptchaChange = (value) => {
        if (value === null || value === undefined)
            setRecaptchaToken('');
        else
            setRecaptchaToken(value);
    };

    //caricamento dati necessari
    const login = () => {

        if (!checkLogin()) {
            return;
        }

        setIsLoading(true);
        var data = {
            'op': 'login',
            'user': username,
            'pwd': password,
            'recaptchaToken': recaptchaToken
        };
        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setIsLoading(false)
                    if (responseJson.status === false) {
                        setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: responseJson.msg })
                        return;
                    }

                    navigate('/verifica-token', { replace: true });
                    return;
                })
                .catch(error => {
                    setIsLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel login, riprovare' })
                })
        );
    };

    return (
        <div className="portale-login" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <form className='portale-container-login login-page'>
                <h2 style={{ textAlign: 'center', marginBottom: '24px' }}>PORTALE MESSAGGISTICA</h2>

                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                    <img alt='Immagine non trovata' src={"/images/AZ-Logo.svg"} className="logo-login" style={{ maxWidth: '200px', height: 'auto' }} />
                </div>

                <div className="input-group" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className="etichetta">USERNAME</div>
                    <input type="text" className="valori" value={username} style={{width: '100%'}}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div className="input-group" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className="etichetta">PASSWORD</div>
                    <input className="valori" type='password' value={password} style={{width: '100%'}}
                        onChange={(event) => setPassword(event.target.value)}
                    />

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <ReCAPTCHA
                        sitekey="6LeyhvApAAAAAOHfIUyJLzQSgFdvbIExb1S6nbCk"
                        onChange={handleCaptchaChange}
                    />
                </div>


                <div className='input-group' style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Button onClick={login} variant="outline-info" className={checkLogin() ? 'button' : 'disabled-button'} style={{ height: '40px', width: '200px', marginTop: '25px' }}>
                        ACCEDI
                    </Button>
                </div>
            </form>

            <ModalLoading visible={isLoading} color="#063970" />
            <ModalError setModalError={setModalError} modalState={modalError} />
        </div>
    );

}

export default LoginPage;