import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { IoClose, IoCloseCircleSharp, IoAddCircleSharp } from "react-icons/io5";
import { PortaleContext } from '../../store/portale-context';
import { Button } from 'react-bootstrap';
import TipiRichiestaTable from '../tables/TipiRichiestaTable';
import { ColumnFilter } from '../ColumnFilter';
import ModalAggiungiTipoRichiesta from './ModalAggiungiTipoRichiesta';
import ModalModificaTipoRichiesta from './ModalModificaTipoRichiesta';
import ModalEliminaTipoRichiesta from './ModalEliminaTipoRichiesta';

function ModalGestioneTipiRichiesta({chiudiModalTipiRichiesta, visible, aggiornaDatiPortale}) {

    const portaleCtx = useContext(PortaleContext);
    const [modalModificaTipoRichiesta, setModalModificaTipoRichiesta] = useState(false);
    const [modalEliminaTipoRichiesta, setModalEliminaTipoRichiesta] = useState(false);
    const [modalAggiungiTipoRichiesta, setModalAggiungiTipoRichiesta] = useState(false);

    const apriModificaTipoRichiesta = (op) => {
        portaleCtx.setTipoRichiestaCorrenteCtx(op);
        setModalModificaTipoRichiesta(true);
    }

    const chiudiModalModificaTipoRichiesta = () => {
        setModalModificaTipoRichiesta(false);
    }

    const apriEliminaTipoRichiesta = (op) => {
        portaleCtx.setTipoRichiestaCorrenteCtx(op);
        setModalEliminaTipoRichiesta(true);
    }

    const chiudiModalEliminaTipoRichiesta = () => {
        setModalEliminaTipoRichiesta(false);
    }

    const columns = [
        { Header: 'Descrizione', accessor: 'Descrizione', Filter: ColumnFilter },
    ];

    return(
            <Modal
                isOpen={visible}
                onRequestClose={() => chiudiModalTipiRichiesta()}
                className="info-modal border-large"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >                
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={() => chiudiModalTipiRichiesta()}
                    />
                </div>

                <h3 className="info-modal-title" style={{marginTop: '0px', marginBottom: '15px'}}>
                    Tipi richiesta
                </h3>

                <div className="info-modal-title" style={{marginTop: '0px', marginBottom: '20px'}}> 
                    <Button className='button green' style={{fontFamily: 'inherit', fontSize: 'inherit'}} onClick={() => {setModalAggiungiTipoRichiesta(true)}}>
                        Aggiungi <IoAddCircleSharp className="text-icon-right" />
                    </Button>
                </div>
                
                <div className='contact-form'>

                    {portaleCtx.tipiRichiesta != undefined ?
                    <TipiRichiestaTable columns={columns} data={portaleCtx.tipiRichiesta} apriModificaTipoRichiesta={apriModificaTipoRichiesta} apriEliminaTipoRichiesta={apriEliminaTipoRichiesta} />
                    : <></>}
                    <div className="buttons" style={{width: '90%', dislay: 'flex', justifyContent: 'center'}}>
                        <Button
                            className="button red"
                            onClick={() => chiudiModalTipiRichiesta()}>
                            Chiudi <IoCloseCircleSharp className="text-icon-right" />
                        </Button>
                    </div>
                </div>

                <ModalAggiungiTipoRichiesta setModalAggiungiTipoRichiesta={setModalAggiungiTipoRichiesta} visible={modalAggiungiTipoRichiesta} aggiornaDatiPortale={aggiornaDatiPortale} />
                <ModalModificaTipoRichiesta chiudiModalModificaTipoRichiesta={chiudiModalModificaTipoRichiesta} visible={modalModificaTipoRichiesta} aggiornaDatiPortale={aggiornaDatiPortale} />
                <ModalEliminaTipoRichiesta chiudiModalEliminaTipoRichiesta={chiudiModalEliminaTipoRichiesta} visible={modalEliminaTipoRichiesta} aggiornaDatiPortale={aggiornaDatiPortale} />

            </Modal>
    );
}

export default ModalGestioneTipiRichiesta;